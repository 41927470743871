<template>
    <div>
    <h1 class="display-3">
        Cloud
    </h1>

    <p class="blockquote" style="padding: 2em;">
        Our goal is to deliver valuable, high quality, well-maintained products at the right cost to our clients.
    </p>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">schema</i> Cloud Architecture</h5>
        <ul>
            <li>Employ Azure and AWS Well-Architected Guidelines.</li>
            <li>Pick the right architecture for your needs based on traffic, skillset and scalability.</li>
            <li>Design software to survive changes in growth and scale.</li>
            <li>Containerize for both traditional application servers and container orchestration platforms like kubernetes.</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">timer</i> Ready-made Microservices</h5>
        <ul>
            <li>Take advantage of our existing microservice solutions for popular vendors like Stripe, Twilio, Apple, Google and many more.</li>
            <li>Get to market faster by reducing development time and costs.</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">check_circle</i> CI/CD + Push, Build, Test and Deploy</h5>
        <ul>
            <li>Simple build, test and deploy pipelines for traditional application servers.</li>
            <li>Complex approvals and validations for tightly controlled deployment strategies.</li>
            <li>Declarative deployments for container orchenstration platforms like kubernetes.</li>
            <li>Build in the cloud, push to mobile App Stores.</li>
        </ul>
    </div>

    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.svc {
    padding-top: 3em;
}
</style>