<template>
    <div>
    <h1 class="display-3">
        Industries
    </h1>

    <p class="blockquote" style="padding: 2em;">
        Our goal is to deliver valuable, high quality, well-maintained products at the right cost to our clients.
    </p>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">medical_services</i> Medical and Health Care</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">build</i> Manufacturing</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">attach_money</i> Money Transfer</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">shop</i> e-Commerce</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">live_help</i> Emergency Services</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    <div class="text-left svc">
        <h5><i class="material-icons align-top">bar_chart</i> Analytics</h5>
        <ul>
            <li>TODO</li>
        </ul>
    </div>

    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.svc {
    padding-top: 3em;
}
</style>