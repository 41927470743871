<template>
<div>
    <h1 class="display-3">
        Apps
    </h1>

    <p class="blockquote" style="padding: 2em;">
        Our goal is to deliver valuable, high quality, well-maintained products at the right cost to our clients.
    </p>

    <p>
        Below is a short list of interesting and material projects. We have extensive experience in Cloud, Mobile, Web, Desktop and Embedded applications. 
    </p>

    <div class="text-left app">
        <h5>Manufacturing Dashboard</h5>
        <h3>
            <i class="material-icons align-middle">phone_android</i>
            <i class="material-icons align-middle">phone_iphone</i>
            <i class="material-icons align-middle">desktop_windows</i>
            <i class="material-icons align-middle">developer_board</i>
        </h3>
        <ul>
            <li>Application: Cross-platform application for monitoring and search capabilities.</li>
            <li>Server: Data aggregation across many protocols such as SECS/GEM, CFX, iTAC and custom log reading.</li>
        </ul>
        <div class="alert alert-success text-right" role="alert">
            <a class="btn btn-primary align-middle" href="/apps/tycho_asm/" target="_blank">Play with the interactive demo <i class="material-icons align-middle">play_arrow</i></a>
        </div>
    </div>

    <div class="text-left app">
        <h5>Continuous Glucose Monitoring</h5>
        <h3>
            <i class="material-icons align-middle">phone_android</i>
            <i class="material-icons align-middle">phone_iphone</i>
            <i class="material-icons align-middle">desktop_windows</i>
            <i class="material-icons align-middle">developer_board</i>
            <i class="material-icons align-middle">devices_other</i>
        </h3>
        <ul>
            <li>Mobile: Android and iOS applications for personal data collection for internal trials.</li>
            <li>Desktop: MARD and configuration application.</li>
            <li>Server: Bulk data collection and configuration application.</li>
            <li>Integrators: Reference Application and SDK.</li>
        </ul>
        <div class="alert alert-success text-right" role="alert">
            <a class="btn btn-primary align-middle" href="/apps/tycho_cgm/" target="_blank">Play with the interactive demo <i class="material-icons align-middle">play_arrow</i></a>
        </div>
    </div>

    <div class="text-left app">
        <h5>Chat</h5>
        <h3>
            <i class="material-icons align-middle">phone_android</i>
            <i class="material-icons align-middle">phone_iphone</i>
        </h3>
        <ul>
            <li>Mobile: Android and iOS components for chat support in any application.</li>
            <li>Backend: Support for ejabberd, Firebase or Twilio Chat.</li>
        </ul>
        <div class="alert alert-info text-right" role="alert">
            <span class="align-middle">Interactive demo coming soon!</span>
        </div>
    </div>

    <div class="text-left app">
        <h5>Social Re-commerce Platform</h5>
        <h3>
            <i class="material-icons align-middle">phone_android</i>
            <i class="material-icons align-middle">phone_iphone</i>
            <i class="material-icons align-middle">desktop_windows</i>
        </h3>
        <ul>
            <li>Mobile: Android and iOS applications to support product sales.</li>
            <li>Server: Web services to support mobile applications, product search, user interest, holding items, etc.</li>
        </ul>
        <div class="alert alert-info text-right" role="alert">
            <span class="align-middle">Interactive demo coming soon!</span>
        </div>
    </div>

    </div>
</template>

<script>
export default {
  name: 'Apps'
}
</script>

<style scoped>
.app {
    padding-top: 3em;
}
</style>