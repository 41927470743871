<template>
<div>
    <h1 class="display-3">
        Contact
    </h1>
    <p class="blockquote" style="padding: 3em;">
        We are based out of San Diego, California.<br/>
        Please feel free to get in contact with us if you have any questions.<br />
        <a href="mailto:info@tychosoftware.com">info@tychosoftware.com</a>
    </p>
</div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>

</style>