<template>
  <div id="app">
    <div class="navbar-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#" v-on:click="page = 'apps'">
          <span v-if="page =='apps'">
            <i class="material-icons align-top">phone_android</i>
          </span>
          <span v-else-if="page =='contact'">
            <i class="material-icons align-top">alternate_email</i>
          </span>
          <span v-else-if="page =='cloud'">
            <i class="material-icons align-top">cloud_queue</i>
          </span>
          <span v-else-if="page =='industries'">
            <i class="material-icons align-top">business</i>
          </span>
          TYCHO Software
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-item nav-link" href="#" v-on:click="page = 'apps'">Apps</a>
            <a class="nav-item nav-link" href="#" v-on:click="page = 'cloud'">Cloud</a>
            <!--<a class="nav-item nav-link" href="#" v-on:click="page = 'industries'">Industries</a>-->
            <a class="nav-item nav-link" href="#" v-on:click="page = 'contact'">Contact</a>
          </div>
        </div>
      </nav>
      <div class="container" style="padding-top: 2em; padding-bottom: 2em">
        <template v-if="page == 'contact'">
          <Contact />
        </template>
        <template v-else-if="page == 'cloud'">
          <Cloud />
        </template>
        <template v-else-if="page == 'industries'">
          <Industries />
        </template>
        <template v-else>
          <Apps />
        </template>
      </div>
        </div>
    </div>
</template>

<script>
import Apps from './components/Apps.vue'
import Contact from './components/Contact.vue'
import Cloud from './components/Cloud.vue'
import Industries from './components/Industries.vue'

export default {
  name: 'TychoSoftware',
  components: {
    Apps, Contact, Cloud, Industries
  },
  data() {
    return {
      page: 'apps'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
